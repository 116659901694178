/* eslint-disable import/prefer-default-export */
import type { PropType } from 'vue'

export const IsProductRelationshipProps = {
  identifier: {
    type: [Number, Array] as PropType<number | number[]>,
    default: null,
  },

  title: {
    type: String as PropType<string | undefined>,
    default: undefined,
  },

  isTitleSmall: {
    type: Boolean,
    default: false,
  },

  isAddToCartModalSimple: {
    type: Boolean,
    default: false,
  },
  gtmList: {
    type: String,
    default: 'Relationship - products',
  },
}

import { Query, field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  base: {
    announcementBarEnabled,
    drmaxCaptchaSiteKeyEnabled,
    generalStoreConfigEnabled,
  },
  rootConfig: {
    global: {
      loggedPriceInfoEnabled,
      watchdog,
    },
  },
  checkout: {
    features: {
      paypalEnabled,
    },
  },
} = useAppConfig()

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

export default () => Query.named('storeConfig')
  .fields(
    [...(generalStoreConfigEnabled
      ? [
          field('trans_email_ident_custom1_email'),
          field('general_store_information_phone'),
          field('drmax_nsf_mobile_app_1'),
          field('drmax_nsf_mobile_app_2'),
          field('drmax_nsf_facebook'),
          field('drmax_nsf_instagram'),
          field('drmax_nsf_youtube'),
          field('drmax_nsf_verify'),
          ...(watchdog.stockAlertEnabled ? [field('drmax_back_in_stock_notification_allow')] : []),
          ...(watchdog.priceAlertEnabled ? [field('drmax_price_notification_allow')] : []),
          ...drmaxCaptchaSiteKeyEnabled
            ? [field('drmax_captcha_site_key')]
            : [],
          field('drmax_nsf_my_account_enabled'),
          field('drmax_nsf_loyality_redirect'),
          field('drmax_nsf_json_pharmacy_list'),
          field('drmax_nsf_json_faq_list'),
          field('drmax_nsf_sentry_date_till'),
          field('drmax_nsf_header_image', [
            field('image_url'),
            field('width'),
            field('height'),
          ]),
          field('drmax_nsf_header_image_mobile', [
            field('image_url'),
            field('width'),
            field('height'),
          ]),
          field('drmax_nsf_image_before_footer', [
            field('image_url'),
            field('width'),
            field('height'),
          ]),
          field('drmax_nsf_image_before_footer_mobile', [
            field('image_url'),
            field('width'),
            field('height'),
          ]),
          field('drmax_nsf_content_baby_calendar_widget'),
          field('cms_page_data_source'),
          field('blog_data_source'),
          field('announcement_data_source'),
          ...(marketplaceEnabled ? [field('drmax_warning_1st_3p_product'), field('warning_unable_to_apply_giftcard_3p')] : []),
          field('checkout_common_map_delivery_times_enabled'),
          field('shipping_method_stock_mapping', [
            field('shipping_method'),
            field('order_type'),
            field('stock_source'),
          ]),
        ]
      : []),
    ...(announcementBarEnabled
      ? [
          field('drmax_announcement_bar_content'),
          field('drmax_announcement_bar_enabled'),
          field('drmax_announcement_bar_enabled_from'),
          field('drmax_announcement_bar_enabled_to'),
          field('drmax_announcement_bar_expiration'),
          field('drmax_announcement_bar_can_close'),
          field('drmax_announcement_bar_type'),
        ]
      : []),
    ...(loggedPriceInfoEnabled
      ? [
          field('drmax_premium_price_toggler_enabled'),
          field('drmax_sorp_promo_price_box_enabled'),
        ]
      : []),
    ...(paypalEnabled ? [field('paypal_client_id')] : [])],
  )
  .shouldBeCached(true)

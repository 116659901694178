import type { Ref } from 'vue'

export interface Tab {
  name: string
  isActive: boolean
  counter?: number
  dataTestId?: string
  id?: string
}

export interface TabGroup {
  registerTab: (tab: Tab) => void
  unregisterTab: (tab: Pick<Tab, 'name'>) => void
}

export default function useTabGroup(tabs: Ref<Tab[]>): TabGroup {
  const registerTab = (tab: Tab) => {
    tabs.value.push(tab)
  }

  const unregisterTab = (tabToRemove: Pick<Tab, 'name'>) => {
    const index = tabs.value.findIndex((tab) => tab.name === tabToRemove.name)
    if (index > -1) {
      tabs.value.splice(index, 1)
    }
  }

  return {
    registerTab,
    unregisterTab,
  }
}

import { createRestClient } from '@nsf/core/RestClient.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: { customerServiceUrl },
} = useRuntimeConfig()

const {
  rootConfig: {
    global: { xTenant },
  },
} = useAppConfig()

const createCustomerServiceClient = (baseURL = customerServiceUrl) => createRestClient({
  baseURL,
  headers: {
    'X-Tenant': xTenant,
  },
  credentials: 'same-origin',
})

export default createCustomerServiceClient

import logger from '@nsf/cms/logger.js'
import { setState } from '@nsf/core/Store.js'
import { getAnnouncement } from '@nsf/cms/repositories/CmsServiceRepository.js'

export const state = () => ({
  announcement: null,
})

export const nuxtServerInit = async ({ dispatch }) => {
  await dispatch('cms/loadAnnouncement')
}

export const mutations = {
  setState,
}

export const actions = {
  setAnnouncement({ commit }, obj) {
    commit('setAnnouncement', obj)
  },

  async loadAnnouncement(store) {
    try {
      const announcement = await getAnnouncement()
      store.commit('setState', { announcement })
    } catch (e) {
      logger.error('[cms-store] loadAnnouncement(%s) failed: %s', e.message)
    }
  },
}

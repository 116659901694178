/* eslint-disable import/prefer-default-export */
import type { PropType } from 'vue'

import type { ProductWithLoaders } from '@nsf/layer-product-relationship/types/ProductWithLoaders.ts'

export const IsProductCellProps = {
  product: {
    type: Object as PropType<ProductWithLoaders>,
    default: () => ({}),
  },
  position: {
    type: Number,
    default: 1,
  },
  isAddToCartModalSimple: {
    type: Boolean,
    default: false,
  },
  gtmList: {
    type: String,
    default: '',
  },
  showBadges: {
    type: Boolean,
    default: true,
  },
}

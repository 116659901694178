export default {
  product: {
    alternateProductSeoDescription: false,
    defaultMaxQty: 1000,
    detail: {
      addAttributeSetToAttributes: true,
      apothekaWidgetEnabled: false,
      disableReviewSource: false,
      disableReviewerName: false,
      showFreeShippingValue: false,
      showLastUpdate: false,
      showLoggedPriceNote: false,
      showPriceInfo: false,
      showPriceNote: false,
      specialDescriptionAttributes: [
        'drmax_desc_pl_introduction',
        'drmax_pl_dm_desc_properties',
        'drmax_pl_dm_desc_table',
        'drmax_pl_dm_desc_composition',
        'drmax_pl_dm_desc_car',
        'drmax_pl_dm_desc_how_to_use',
        'drmax_pl_dm_desc_medication_in_children',
        'drmax_pl_dm_desc_taking_other_medicines',
        'drmax_pl_dm_desc_daily_intake',
        'drmax_pl_dm_desc_destiny',
        'drmax_pl_dm_desc_pregnancy',
        'drmax_pl_dm_desc_warnings',
        'drmax_pl_dm_desc_side_effects',
        'drmax_pl_dm_desc_weight',
        'drmax_pl_dm_desc_capacity',
        'drmax_pl_dm_desc_indications',
        'drmax_pl_dm_desc_contents',
        'drmax_pl_dm_desc_contraindications',
        'drmax_pl_dm_desc_composition_inci',
        'drmax_pl_dm_desc_table',
        'drmax_pl_dm_desc_contraindications_and_precautions',
        'drmax_pl_dm_desc_possible_side_effects',
        'drmax_pl_dm_desc_warnings_and_precautions',
        'drmax_pl_dm_desc_action',
        'drmax_pl_dm_desc_dosage',
        'drmax_pl_dm_desc_series',
        'drmax_pl_dm_desc_distributor',
        'drmax_pl_dm_desc_manufacturer',
        'drmax_pl_dm_desc_comments',
      ],
      titleRowsSeparated: false,
      useFreeShippingThreshold: false,
      visibleAttributeCodes: [],
    },
    infoBoxEnabled: true,
    isAttributeSetShowed: false,
    reviewsEnabled: false,
    shortDescriptionShowedOn: [
      'category',
      'eLeader',
      'productDetail',
      'single',
      'topProducts',
    ],
    wrapperProductPimFilter: [
      'Temporary unavailable',
      'Visible',
      'Permanently unavailable',
      'Delisted - sold off',
      'Promo',
    ],
  },
}

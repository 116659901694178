import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  allowedSources: [
    '*.algolia.net',
    '*.algolianet.com',
    '*.clarity.ms',
    '*.drmax-gl.dev',
    '*.drmax-gl.space',
    '*.drmax-pl.space',
    '*.drmax.pl',
    '*.drmax.zone',
    '*.drmax-gl.live',
    '*.elfsight.com',
    '*.facebook.com',
    '*.facebook.net',
    '*.fbcdn.net',
    '*.google-analytics.com',
    '*.google.com',
    '*.googleapis.com',
    '*.instagram.com',
    '*.sentry.io',
    '*.twitter.com',
    'api.luigisbox.com',
    'bam.eu01.nr-data.net',
    'callme360.com',
    'cdn.jsdelivr.net',
    'cdn.luigisbox.com',
    'cdn.speedcurve.com',
    'consent.cookiebot.com',
    'consentcdn.cookiebot.com',
    'embed.staffino.com',
    'fonts.gstatic.com',
    'googleads.g.doubleclick.net',
    'image-resizer-svc.drmax-gl.live',
    'image-resizer-svc.drmax-gl.space',
    'insights.algolia.io',
    'js-agent.newrelic.com',
    'measurement-api.criteo.com',
    'pagead2.googlesyndication.com',
    'placement-service.drmax-gl.live',
    'placement-service.drmax-gl.space',
    'rtp.persoo.ai',
    's.pinimg.com',
    'scripts.persoo.cz',
    'search-service.drmax-gl.space',
    'static.cloudflareinsights.com',
    'stats.g.doubleclick.net',
    'universe-static.elfsightcdn.com',
    'unpkg.com',
    'www.googleoptimize.com',
    'www.googletagmanager.com',
    'www.gstatic.com',
    'www.recaptcha.net',
    'www.redditstatic.com',
    'www.youtube-nocookie.com',
    'youtube.com',
  ],
})

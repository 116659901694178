import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getPlacementProducts } from '@nsf/product-relationship-loader-placement/repositories/PlacementRepository.js'

const {
  productRelationshipLoaderPlacement: {
    placementHomepageLimit,
  },
} = useAppConfig()

/**
 * @type {import('@nsf/layer-product-relationship/types/AbstractLoader').AbstractLoader}
 */
export default function () {
  const userId = window?.nsf?.session?.deviceCache ?? ''
  const config = this?.config ?? { limit: placementHomepageLimit }
  return getPlacementProducts('last-viewed', userId, config, this?.ctx)
}

export default (placement) => {
  switch (placement) {
    case 'category-top':
      return 'category'
    case 'eleader':
      return 'category'
    case 'homepage-top':
      return 'homepage'
    case 'pre-cart':
      return 'cart'
    case 'last-viewed':
      return 'homepage'
    case 'crossell':
      return 'productDetail'
    case 'related':
      return 'productDetail'
    case 'cart-crossell':
      return 'cart'
    case 'hero':
      return 'homepage'
    default:
      return placement
  }
}

/* eslint-disable import/prefer-default-export */
import { mapDataToProduct } from '@nsf/catalog/mappers/ProductMapper.js'
import type { Product } from '@nsf/catalog/types/Product.ts'
import type { ProductWithLoaders } from '@nsf/layer-product-relationship/types/ProductWithLoaders.ts'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'

export const mapDataToRelationshipLoaderProducts = (
  data: Product[],
  loaderLabel: string,
  loaderType: string,
): ProductWithLoaders[] => ensureArray(mapDataToProduct(data)).map((pr: Product) => ({
  ...pr,
  loaderLabel,
  loaderType,
}))

export default {
  app: {
    metaDescriptionCutoff: 160,
    noIndexParams: [
      'amp;refresh',
      'amp;menu',
      'amp',
      'cjevent',
      'clientid',
      'cms_preview',
      'filter',
      'fresh',
      'id',
      'menu',
      'p',
      'promoname',
      'q',
      'range',
      'rating',
      'refresh',
      'refreshing',
      'ruleid',
      'search',
      'sku',
      'ppcbee-adtext-variant',
      'keyword',
    ],
    marketingParams: [
      'fbclid',
      'y_source',
      'gbraid',
      'cjdata',
      'network',
      'gclid',
    ],
    prefix: {
      amasty: '/media/amasty/amfile',
      catalog: '/media/catalog',
      drmax: '/media/drmax',
      elastic: '/api/catalog/drmax_eshop_1',
      images: '/api/ext/images',
    },
  },
}

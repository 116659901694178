import logger from '@nsf/my-account/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import removeAllPriceAlertsMutation from '../gql/mutations/removeAllPriceAlertsMutation.js'
import removeAllStockAlertsMutation from '../gql/mutations/removeAllStockAlertsMutation.js'
import removePriceAlertHashMutation from '../gql/mutations/removePriceAlertHashMutation.js'
import removePriceAlertMutation from '../gql/mutations/removePriceAlertMutation.js'
import removeStockAlertHashMutation from '../gql/mutations/removeStockAlertHashMutation.js'
import removeStockAlertMutation from '../gql/mutations/removeStockAlertMutation.js'
import subscribePriceAlertMutation from '../gql/mutations/subscribePriceAlertMutation.js'
import subscribeStockAlertMutation from '../gql/mutations/subscribeStockAlertMutation.js'
import priceAlertQuery from '../gql/queries/priceAlertQuery.js'
import stockAlertQuery from '../gql/queries/stockAlertQuery.js'

const {
  rootConfig: {
    global: {
      watchdog: {
        priceAlertEnabled,
        stockAlertEnabled,
      },
    },
  },
} = useAppConfig()

export const postSubscribeWatchdog = async ({
  product: { id: productId },
  watchdogEmail: email,
  userName: name,
  priceAlertValue,
  isPriceAlert,
}) => {
  try {
    if (isPriceAlert) {
      const response = await subscribePriceAlertMutation()
        .bind({
          email,
          name,
          product_id: productId,
          price: priceAlertValue,
        })
        .post()
      return {
        guest: response.guest ?? false,
        customer_id: response.customer_id ?? false,
        errors: response?.errors ?? false,
      }
    }
    const response = await subscribeStockAlertMutation()
      .bind({
        productId,
        email,
        name,
      })
      .post()
    return {
      guest: response.guest ?? false,
      customer_id: response.customer_id ?? false,
      errors: response?.errors ?? false,
    }
  } catch (e) {
    logger.error(
      'postSubscribeWatchdog(%o, %o, %o) failed: %s',
      productId,
      email,
      name,
      e.message,
    )

    return {
      guest: false,
      customer_id: false,
      errors: [{ message: 'internal server error' }],
    }
  }
}

function getAlertsProducts(products, type) {
  return (products ?? []).map((item) => {
    item.type = type
    return item
  })
}

async function getPriceAlertsProducts(userEmail) {
  try {
    const priceAlerts = await priceAlertQuery()
      .bind({ email: userEmail })
      .get()
    return {
      products: getAlertsProducts(priceAlerts.products, 'priceAlert'),
      isError: false,
    }
  } catch (e) {
    logger.error(
      'getPriceAlertsProducts failed: %s',
      e.message,
    )
    return {
      products: [],
      isError: true,
    }
  }
}

async function getStockAlertsProducts(userEmail) {
  try {
    const stockAlerts = await stockAlertQuery().bind({ email: userEmail })
      .get()

    return {
      products: getAlertsProducts(stockAlerts.products, 'stockAlert'),
      isError: false,
    }
  } catch (e) {
    logger.error(
      'getStockAlertsProducts failed: %s',
      e.message,
    )
    return {
      products: [],
      isError: true,
    }
  }
}

export const getWatchdogsForUserEmail = async (userEmail) => {
  let priceAlerts = []
  let stockAlerts = []
  let isErrorPriceAlerts = false
  let isErrorStockAlerts = false

  if (priceAlertEnabled) {
    const { products, isError } = await getPriceAlertsProducts(userEmail)
    priceAlerts = products
    isErrorPriceAlerts = isError
  }

  if (stockAlertEnabled) {
    const { products, isError } = await getStockAlertsProducts(userEmail)
    stockAlerts = products
    isErrorStockAlerts = isError
  }

  return {
    stockAlerts,
    priceAlerts,
    isErrorPriceAlerts,
    isErrorStockAlerts,
  }
}

export const removeStockAlert = async ({ userEmail, productId }) => {
  try {
    return await removeStockAlertMutation()
      .bind({ userEmail, productId })
      .post()
  } catch (e) {
    logger.error('removeStockAlert failed: %s', e.message)
    return { message: 'Failed' }
  }
}

export const removePriceAlert = async ({ userEmail, productId }) => {
  try {
    return await removePriceAlertMutation()
      .bind({ userEmail, productId })
      .post()
  } catch (e) {
    logger.error('removePriceAlert failed: %s', e.message)
    return { message: 'Failed' }
  }
}

export const removeStockAlertHash = async (hash, getClientData = false) => {
  try {
    return await removeStockAlertHashMutation()
      .bind({ hash, getClientData })
      .post()
  } catch (e) {
    logger.error('removeStockAlertHash failed: %s', e.message)
    return { message: 'Failed' }
  }
}

export const removePriceAlertHash = async (hash, getClientData = false) => {
  try {
    return await removePriceAlertHashMutation()
      .bind({ hash, getClientData })
      .post()
  } catch (e) {
    logger.error('removePriceAlertHash failed: %s', e.message)
    return { message: 'Failed' }
  }
}

export const removeAllStockAlerts = async ({ userEmail }) => {
  try {
    const { message } = await removeAllStockAlertsMutation().bind({ userEmail })
      .post()
    return {
      message,
    }
  } catch (e) {
    logger.error('removeAllStockAlerts failed: %s', e.message)
    return {
      message: 'Failed',
    }
  }
}

export const removeAllPriceAlerts = async ({ userEmail }) => {
  try {
    const { message } = await removeAllPriceAlertsMutation()
      .bind({ userEmail })
      .post()
    return {
      message,
    }
  } catch (e) {
    logger.error('removeAllPriceAlerts failed: %s', e.message)
    return {
      message: 'Failed',
    }
  }
}

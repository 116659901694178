import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import cartItemPrices from './cartItemPrices.js'

const {
  checkout: {
    features: {
      cartItemsAllowBackOrdersEnabled,
      cartItemsMaxQtyNotificationEnabled,
      cartItemsStockEnabled,
      freeShippingViaSalesRulesEnabled,
    },
  },
} = useAppConfig()

export default field('applied_sales_rules', [
  field('id'),
  field('type'), // item|generic - if item then product info is available otherwise it is bundle or similar rule
  field('label'),
  field('units'),
  field('discount', [
    field('currency'),
    field('value'),
  ]),
  field('items', [
    field('id'),
    field('quantity'),
    cartItemPrices,
    field('product', [
      field('id'),
      field('name'),
      field('manufacturer_name'),
      field('drmax_pim_status'),
      field('main_category', [
        field('name'),
        field('path'),
      ]),
      field('sku'),
      field('url_key'),
      field('thumbnail', [
        field('url'),
        field('path'),
      ]),
      field('categories', [
        field('id'),
        field('name'),
        field('path'),
        field('url_key'),
      ]),
      field('price', [
        field('exclVatPrice'),
        field('regularPrice', [
          field('amount', [
            field('currency'),
            field('value'),
          ]),
        ]),
      ]),
      field('saleQty', [
        field('maxQty'),
        field('minQty'),
        ...cartItemsMaxQtyNotificationEnabled ? [field('maxQtyNotification')] : [],
        ...cartItemsStockEnabled ? [field('stock')] : [],
        ...cartItemsAllowBackOrdersEnabled ? [field('allow_backorders')] : [],
      ]),
    ]),
  ]),
  field('caused_by'),
  ...freeShippingViaSalesRulesEnabled ? [field('free_shipping')] : [],
  field('reservation_allowed'),
  field('is_visible_on_fe'),
])

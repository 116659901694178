import logger from '@nsf/layer-subscription/logger.js'
import confirmSubscriberMutation from '@nsf/layer-subscription/app/gql/mutations/confirmSubscriberMutation.js'
import subscribeUserMutation from '@nsf/layer-subscription/app/gql/mutations/subscribeUserMutation.js'
import type { SubscriptionStatus } from '@nsf/layer-subscription/types/subscription'

export const subscriptionSuccess: SubscriptionStatus = {
  message: 'subscription.Success_message',
  statusIcon: 'success',
  iconClass: 'bg-success-highlighted',
}
export const subscriptionFailure: SubscriptionStatus = {
  message: 'subscription.Failure_message',
  statusIcon: 'alert',
  iconClass: 'bg-failure-highlighted',
}

export const subscribeUser = async (email: string, token: string) => {
  try {
    const { status } = await subscribeUserMutation().bind({ email, token })
      .post()

    return status
  } catch (e) {
    logger.withTag('graphql').error('subscribeUser(%o, %o) failed: %s', email, token, e.message)

    return 'error'
  }
}

export const confirmSubscription = async (subscriberId: string, code: string) => {
  try {
    const { status } = await confirmSubscriberMutation().bind({ subscriberId, code })
      .post()

    return status
  } catch (e: unknown) {
    logger.withTag('graphql').error('confirmSubscription(%o, %o) failed: %s', subscriberId, code, e.message)

    return 'error'
  }
}

/**
 * Documentation: https://mydrmax.atlassian.net/wiki/spaces/WAD/pages/3386802177/User+context+v1
 */

import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

/**
 * @param {Object} user
 */
export const pushUserEvent = (
  user = null,
) => {
  const event = {
    id: user?.clientId,
    firstname: user?.firstName,
    surname: user?.lastName,
    email: user?.email,
    phone: user?.phoneNumber,
    birthDate: user?.birthDate,
    gender: user?.sex,
    accountType: user?.accountType === 'O' ? 'full_account' : 'limited_account',
  }

  if (user) {
    usePushEvent({ user: event })
  }
}

import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { Mutation, variable, field } from '@nsf/core/GraphQL.js'

export default () => Mutation.named('createSubscriber')
  .where('input', {
    // eslint-disable-next-line camelcase
    subscriber_email: variable('email', REQUIRED_STRING),
    token: variable('token', REQUIRED_STRING),
  })
  .fields([
    field('status'),
  ])

export const state = () => ({
  alertMessage: null,
  alertType: null,
})

export const mutations = {
  setAlert(state, { message, type }) {
    state.alertMessage = message
    state.alertType = type
  },
  clearAlert(state) {
    state.alertMessage = null
    state.alertType = null
  },
}

export const actions = {
  showAlert({ commit }, { message, type = 'success' }) {
    commit('setAlert', { message, type })
  },
  clearAlert({ commit }) {
    commit('clearAlert')
  },
}

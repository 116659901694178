import { Mutation, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT, REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('confirmSubscriber')
  .where('input', {
    // eslint-disable-next-line camelcase
    subscriber_id: variable('subscriberId', REQUIRED_INT, 0),
    code: variable('code', REQUIRED_STRING, ''),
  })
  .fields([
    field('status'),
  ])

import {
  confirmSubscription,
  subscriptionFailure,
  subscriptionSuccess,
// eslint-disable-next-line import/extensions
} from '@nsf/layer-subscription/app/utils/subscription'

export default async (queryId: string, queryCode: string) => {
  if (!queryId || !queryCode) {
    return subscriptionFailure
  }

  const response = await confirmSubscription(queryId, queryCode)

  return response === 'ok' ? subscriptionSuccess : subscriptionFailure
}

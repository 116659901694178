/* eslint-disable sort-keys */
/* eslint-disable no-shadow */
import { setState } from '@nsf/core/Store.js'
import logger from '@nsf/my-account/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import {
  getCustomerGroupId,
  getDefaultUser,
  getCurrentUser,
} from '../repositories/UserRepository.js'

const {
  myAccount: {
    features: {
      favoriteProductsEnabled,
    },
  },
} = useAppConfig()

export const state = () => ({
  isInitialized: false,
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,

  user: getDefaultUser(),
  userGroupId: null,

  loyaltyProgramme: {
    clientId: null,
    details: null,
  },
})

export const getters = {
  getLoyaltyCardDetails: (state) => state.loyaltyProgramme.details,
  getSavingTotal: (state) => state.user.savingTotal,
  getCardCode: (state) => state.user.cardCode,
}

export const mutations = {
  setState,

  setLoyaltyCardClientId(state, payload) {
    state.loyaltyProgramme.clientId = payload
  },

  setLoyaltyCardDetails(state, payload) {
    state.loyaltyProgramme.details = payload
  },
}

export const actions = {
  async afterUpdate({ dispatch, commit }) {
    const { user, ok } = await getCurrentUser()
    commit('my-account/login/setState', { isLoggingIn: false, isInitialized: true, isLoggedIn: ok })
    if (ok) {
      dispatch('setUser', user)
    }
  },

  async setUser({ commit }, user = getDefaultUser()) {
    commit('setState', { user })

    const { customerGroupId } = await getCustomerGroupId(user.accountType)
    commit('setState', { userGroupId: customerGroupId })
  },

  async fetchUserActions({ dispatch, state }, headers) {
    const { isLoggedIn } = state

    if (isLoggedIn) {
      const { email } = state.user
      if (email) {
        dispatch('my-account/watchdogs/loadWatchdogs', { email }, { root: true })
      } else {
        logger.error('Failed getting email on user init store: %s', state.user.clientId)
      }
    }

    if (favoriteProductsEnabled) {
      dispatch('my-account/favorites/loadFavoriteProducts', { isLoggedIn, headers }, { root: true })
    }
  },

  async removeUserActions({ dispatch, commit }) {
    commit('my-account/watchdogs/setWatchdogs', [], { root: true })
    dispatch('my-account/favorites/removeFavoriteProducts', null, { root: true })
  },
}

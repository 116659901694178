import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getPlacementData } from '@nsf/product-relationship-loader-placement/repositories/PlacementRepository.js'

const {
  productRelationshipLoaderPlacement: {
    placementELeaderLimit,
  },
} = useAppConfig()

/**
 * @type {import('@nsf/layer-product-relationship/types/AbstractLoader').AbstractLoader}
 */
export default async function () {
  const userId = ''
  const config = this?.config ?? { limit: placementELeaderLimit }
  return await getPlacementData('eleader', userId, config, this?.ctx)
}

import Vue from 'vue'
import flattenDeep from 'lodash/flattenDeep.js'
import {
  hasKeys, isString, isObject, isNullish, isArray, isPlainObject,
} from '@nsf/core/Utils.js'
import { FetchError } from '@nsf/core/Communication.js'
import { useLogger } from '@nsf/use/composables/useLogger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { v4 as uuidv4 } from 'uuid'

const {
  rootConfig: {
    global: {
      multiStoreEnabled,
      newUrlResolverEnabled,
    },
  },
} = useAppConfig()

const logger = useLogger('GraphQL')

const {
  public: {
    appUrl,
    deliveryCalculatorUrl,
    environmentName,
    magentoUrl,
    magentoUrlInternal,
    storeViewCode,
  },
} = useRuntimeConfig()

export class Gql {
  constructor() {
    this._arguments = {}
    this._fields = {}
  }

  /**
   * Add condition to the GQL
   *
   * @param {string|any} key
   * @param {any} value
   * @returns {Query}
   */
  where(key, value) {
    if (isObject(key) && isNullish(value)) {
      Object.entries(key).forEach(([key, value]) => this.where(key, value))
    } else {
      this._arguments[key] = value
    }

    return this
  }

  /**
   * Sets fields of the Query
   *
   * @param {Field[]} fields
   * @returns {Query}
   */
  fields(fields) {
    this._fields = {}

    for (const field of fields) {
      this._fields[field._alias + field._name] = field
    }

    return this
  }

  _buildArguments() {
    if (!hasKeys(this._arguments)) {
      return ''
    }

    const conditions = Object.entries(this._arguments)
      .map(([key, value]) => `${key}:${this._stringify(value)}`)
      .join(',')

    return `(${conditions})`
  }

  _buildFields() {
    if (!hasKeys(this._fields)) {
      return ''
    }

    const fields = Object.values(this._fields).map((field) => field.toString())

    return `{${fields}}`
  }

  /**
   * @param {any} value
   * @returns {string}
   */
  _stringify(value) {
    if (isNullish(value)) {
      return 'null'
    }

    if (isString(value)) {
      return `"${value}"`
    }

    if (isArray(value)) {
      const stringified = value.map((val) => this._stringify(val)).join(',')

      return `[${stringified}]`
    }

    if (isPlainObject(value)) {
      const stringified = Object.entries(value)
        .map(([key, val]) => `${key}:${this._stringify(val)}`)
        .join(',')

      return `{${stringified}}`
    }

    return value.toString()
  }
}

export class Request extends Gql {
  constructor(type, name, shouldBeCached = false) {
    super()
    this._type = type
    this._name = name
    this._bindings = {}
    this._shouldBeCached = shouldBeCached
    this._preserveCookies = false
  }

  /**
   * Binds variables to the Query
   *
   * @param {string|any} binding
   * @param {any} [value]
   * @return {Query}
   */
  bind(binding, value) {
    if (isObject(binding) && isNullish(value)) {
      Object.entries(binding).forEach(([key, value]) => this.bind(key, value))
    } else {
      this._bindings[binding] = value
    }

    return this
  }

  /**
   * Indicates whether cookies from FE should be send to BE
   *
   * @param {boolean} preserveCookies
   * @return {Query}
   */
  preserveCookies(preserveCookies = false) {
    this._preserveCookies = preserveCookies

    return this
  }

  /**
   * Indicates whether response to the query should be stored in cache
   * Works only for GET requests, if POST is used, option is ignored
   *
   * @param {boolean} shouldBeCached
   * @return {Query}
   */
  shouldBeCached(shouldBeCached = false) {
    this._shouldBeCached = shouldBeCached

    return this
  }

  toDeliveryCalculator() {
    this._toDeliveryCalculator = true
    this._addCorrelationIdHeader = true

    return this
  }

  /**
   * Builds an array of arguments
   *
   * @param {Variable|Array|{}} value
   * @param {Array} stringArray
   * @return {string[]}
   */
  _getInputStringArray(value, stringArray = []) {
    if (value instanceof Variable) {
      const defaultValue = value._defaultValue ? `=${value._defaultValue}` : ''
      stringArray.push([`$${value._name}:${value._type}${defaultValue}`])
    } else if (Array.isArray(value)) {
      value.forEach((nestedVal) => {
        this._getInputStringArray(nestedVal, stringArray)
      })
    } else if (typeof value === 'object') {
      Object.values(value).forEach((nestedVal) => {
        this._getInputStringArray(nestedVal, stringArray)
      })
    }

    return flattenDeep(stringArray)
  }

  /**
   * Builds an input string from an array of arguments
   *
   * @param {Variable|Array|{}} value
   * @return {string}
   */
  _getInputString(value) {
    const stringArray = this._getInputStringArray(value)
    return stringArray.length ? `(${stringArray.join(',')})` : ''
  }

  /**
   * Extracts all nested arguments and returns them as an object
   *
   * @param {[]} fields
   * @param {{}} nestedArguments
   */
  _extractNestedArguments(fields, nestedArguments = {}) {
    for (const key in fields) {
      const { _arguments, _fields } = fields[key]

      if (Object.keys(_arguments).length) {
        nestedArguments = { ..._arguments, ...nestedArguments }
      }

      if (Object.keys(_fields).length) {
        return this._extractNestedArguments(_fields, nestedArguments)
      }
    }

    return nestedArguments
  }

  /**
   * @returns {string}
   */
  toString() {
    const type = this._type
    const name = this._name
    const args = this._buildArguments()
    const fields = this._buildFields()

    const nestedArguments = this._extractNestedArguments(this._fields)
    const inputString = this._getInputString({ ...this._arguments, ...nestedArguments })

    return name
      ? `${type}${inputString}{${name}${args}${fields}}`
      : `${inputString}${type}${name}${args}${fields}`
  }

  _cloneFrom(src) {
    this._type = src._type
    this._name = src._name
    this._arguments = { ...src._arguments }
    this._bindings = { ...src._bindings }
    this._fields = { ...src._fields }
    this._shouldBeCached = src._shouldBeCached
  }

  /**
   * Handles errors
   *
   * @param {Array} errors
   */
  handleGraphQLErrors = (errors) => {
    gqlListeners.errors = errors

    for (const error of errors) {
      // cmsBlocks are not mandatory, they are used to fetch extra info/content
      if (!error.path?.[0] === 'cmsBlock') {
        logger
          .withTag(error.category)
          .error(error.message)
      }
    }
  }

  _getRequestUrl(method = '', isServer = process.server) {
    if (this._toDeliveryCalculator) {
      return `${deliveryCalculatorUrl}/graphql`
    }

    if (newUrlResolverEnabled && this._name === 'urlResolver') {
      if (isServer) {
        return '/urlresolv.php'
      }

      return '/urlres'
    }

    if (method === 'GET') {
      return '/graphql'
    }

    return `${isServer ? magentoUrlInternal : appUrl}/graphql`
  }

  async _fetch(method, query) {
    const url = this._getRequestUrl(method)

    try {
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': this._shouldBeCached && method === 'GET' ? 'public' : 'no-store',
        ...(multiStoreEnabled && storeViewCode
          ? {
              Store: storeViewCode,
            }
          : {}),
        ...(this._preserveCookies
          ? {
              'Preserve-Cookies': 'true',
            }
          : {}),
        ...(this._addCorrelationIdHeader
          ? {
              'X-Correlation-Id': uuidv4(),
            }
          : {}),
      }

      const operationName = this._name
      const hasVariables = Object.keys(this._bindings).length

      const encodedVariables = encodeURIComponent(JSON.stringify(this._bindings))

      const variablesString = hasVariables ? `&variables=${encodedVariables}` : ''
      const encodedQuery = encodeURIComponent(query)
      const urlQuery = this._toDeliveryCalculator
        ? `?query=${encodedQuery}${variablesString}`
        : `?query=${encodedQuery}&operationName=${operationName}${variablesString}`
      const urlWithQuery = `${url}${urlQuery}${!this._shouldBeCached ? '&fresh' : ''}`

      const body = JSON.stringify({
        query,
        operationName,
        ...(hasVariables && { variables: this._bindings }),
      })

      const _get = () => fetch(
        // For SSR calls must be specified an absolute path in fetch(
        (process.server ? magentoUrlInternal || magentoUrl : '') + urlWithQuery,
        { method, headers },
      )
      const _post = () => fetch(url, { method, headers, body })

      const response = method === 'GET' ? await _get() : await _post()

      const json = await response.json()

      if (json.errors) {
        this.handleGraphQLErrors(json.errors)
      }

      return json
    } catch (e) {
      switch (environmentName) {
        case 'local':
          logger.error(`Backend response is not valid, please check that backend at: ${url} is up and running.`)
          logger.error(e)
          throw new FetchError('Request failed')
        default:
          logger.error(e.message)
          throw new FetchError('Request failed')
      }
    }
  }
}

export class Query extends Request {
  constructor(name) {
    super('query', name)
  }

  /**
   * @param {string} name
   * @returns {Query}
   */
  static named(name) {
    return new Query(name)
  }

  /**
   * @param {Field[]} fields
   * @returns {Query}
   */
  static multi(fields) {
    const query = new Query('')
    query.fields(fields)
    return query
  }

  /**
   * @returns {Query}
   */
  clone() {
    const query = new Query(this._name)

    query._cloneFrom(this)

    return query
  }

  async get() {
    const response = await this._fetch('GET', this.toString())

    if (response.errors || !response.data) {
      return response
    }
    if (this._name) {
      return response.data[this._name]
    }
    return response.data
  }
}

export class Mutation extends Request {
  constructor(name) {
    super('mutation', name)
  }

  /**
   * @param {string} name
   * @returns {Mutation}
   */
  static named(name) {
    return new Mutation(name)
  }

  /**
   * @param {Field[]} fields
   * @returns {Query}
   */
  static multi(fields) {
    const query = new Mutation('')
    query.fields(fields)
    return query
  }

  /**
   * @returns {Mutation}
   */
  clone() {
    const mutation = new Mutation(this._name)

    mutation._cloneFrom(this)

    return mutation
  }

  async post() {
    const response = await this._fetch('POST', this.toString())
    if (response.errors) {
      return response
    }
    if (this._name) {
      return response.data[this._name]
    }
    return response.data
  }
}

export class Field extends Gql {
  constructor(name) {
    super()
    this._name = name
    this._alias = ''
  }

  /**
   * @deprecated use "fields" method instead
   */
  subfields(subfields) {
    return this.fields(subfields)
  }

  /**
   * @param {String} alias
   * @returns {Field}
   */
  alias(alias) {
    this._alias = `${alias}:`
    return this
  }

  toString() {
    const name = this._name
    const alias = this._alias
    const args = this._buildArguments()
    const fields = this._buildFields()

    return `${alias}${name}${args}${fields}`
  }
}

export class Variable {
  constructor(name, type, defaultValue) {
    this._name = name
    this._type = type
    this._defaultValue = defaultValue
  }

  toString() {
    return `$${this._name}`
  }
}

/**
 * Creates new field
 *
 * @param {string} name
 * @param {Field[]} fields
 * @returns {Field}
 */
export const field = (name, fields = []) => new Field(name).fields(fields)

/**
 * Creates new fragment spread expression
 *
 * @param {string} name
 * @param {Field[]} fields
 * @returns {Field}
 */
export const on = (name, fields = []) => [new Field(`... on ${name}`).fields(fields)]

/**
 * Creates new variable
 *
 * @param {string} name
 * @param type
 * @param defaultValue
 */
export const variable = (name, type, defaultValue) => new Variable(name, type, defaultValue)

export const gqlListeners = Vue.observable({
  errors: [],
})

/**
 * @param {Gql} gql
 * @param {Field} field
 * @param {string[]} chain - where in deep of {Fields} structure you want to append {Field}
 */
export const addField = (gql, field, chain = []) => {
  let locale = gql._fields
  for (let i = 0; i < chain.length; i++) {
    if (Object.prototype.hasOwnProperty.call(locale, chain[i])) {
      locale = locale[chain[i]]._fields
    }
  }
  locale[field._name] = field
}

/**
 * @param {Gql} gql
 * @param {string|string[]} ident
 * @returns {Field}
 */
export const getField = (gql, ident) => {
  const path = isArray(ident) ? ident : ident.split('.')

  if (path.length === 0) {
    return gql
  }

  const name = path.shift()

  const field = gql._fields[name]

  if (isNullish(field)) {
    return null
  }

  return getField(field, path)
}

/**
 * @param {Gql} gql
 * @param {string|string[]} ident
 */
export const removeField = (gql, ident) => {
  const path = isArray(ident) ? ident : ident.split('.')
  const name = path.pop()

  const parent = getField(gql, path)

  delete parent._fields[name]
}

/**
 * @param {Gql} gql
 * @param {string|string[]} ident
 */
export const removeWhere = (gql, ident) => {
  const path = isArray(ident) ? ident : ident.split('.')
  const name = path.pop()

  let obj = gql._arguments
  for (const part of path) {
    obj = obj[part]
  }

  delete obj[name]
}
